import React from 'react'
import styled from 'styled-components'

// import { NavLink } from "react-router-dom";

// Core
import { PageWrapper } from '../../elements/pageContainer'
import BuildLinkElement from '../../elements/linkBuilder'

import { ReactComponent as Logo } from '../../../assets/svg/logo.svg'
import { ReactComponent as FacebookLogo } from './icons/facebook.svg'
import { ReactComponent as InstagramLogo } from './icons/instagram.svg'
import { ReactComponent as LinkedInLogo } from './icons/linkedin.svg'

import { ReactComponent as VimeoLogo } from './icons/vimeo.svg'
import { ReactComponent as YoutubeLogo } from './icons/youtube.svg'
import { ReactComponent as PinterestLogo } from './icons/pinterest.svg'
import { ReactComponent as TwitterLogo } from './icons/square-x-twitter.svg'

const socialMap = {
  facebook: FacebookLogo,
  instagram: InstagramLogo,
  linkedin: LinkedInLogo,
  twitter: TwitterLogo,
  pinterest: PinterestLogo,
  youtube: YoutubeLogo,
  vimeo: VimeoLogo,
}

// Module specific
const FooterWrapper = styled.div`
  background: #f7fbfc;

  .footerContent {
    padding: 40px 0;
  }

  .subFooterBlock {
    background: #005496;
    min-height: 80px;
    position: relative;

    .contentWrapper {
      line-height: 60px;

      .sublink {
        display: inline-block;
        vertical-align: middle;
        min-width: 100px;
        padding: 4px 22px 4px 0;

        span a {
          font-weight: 200;
          font-size: 0.75rem;
        }
      }

      .sublinkContainer {
        display: inline-block;
        vertical-align: middle;
        width: 40%;
        padding: 4px 0px;

        @media screen and (max-width: 1400px) {
          width: 100%;
          text-align: left !important;
        }
      }

      .textContainer {
        display: inline-block;
        vertical-align: middle;
        width: 30%;
        color: #fff;
        font-size: 0.75rem;
        font-weight: 200;
        text-align: center;
        padding: 4px 0px;

        a {
          color: #fff;
        }

        &.alignRight {
          text-align: right;
        }

        @media screen and (max-width: 1400px) {
          width: 50%;
          text-align: left !important;
        }
      }
    }
  }

  .footerContentBlock {
    // width: 300px;
    display: inline-block;
    vertical-align: top;
    padding-top: 32px;

    a {
      display: block;
      color: #2468a2;
      font-size: 0.8rem;
      padding: 5px 0;
    }

    ul,
    li {
      list-style: none;
      padding: 0;
    }

    li {
      padding: 2px 0;

      span a {
        font-weight: 200;
      }
    }

    .restItem {
      display: inline-block;
      width: 50%;
    }

    h3 {
      font-weight: 400;
      color: #075998;
      font-size: 0.75rem;
    }

    &.general {
      width: 25%;
    }
    &.rest {
      width: 50%;
    }
    &.logos {
      width: 25%;

      > svg {
        padding-bottom: 2rem;
        display: block;
      }

      a {
        display: inline-block;
        padding: 0;
        border-radius: 4px;
        overflow: hidden;
        margin: 5px;
        width: 31px;
        height: 31px;
        
        &.icon-twitter {
          position: relative;
          top: 0px;
          svg {
            position: relative;
            top: -3px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1000px) {
    .subFooterBlock {
      padding: 22px 0;
      line-height: 40px !important;

      .contentWrapper {
        line-height: 40px !important;

        .sublinkContainer {
          width: 100%;
          line-height: 40px !important;
        }

        .textContainer {
          width: 100%;
          line-height: 40px !important;
          text-align: left !important;
        }
      }
    }
  }

  @media screen and (max-width: 1000px) {
    .footerContentBlock {
      width: 100%;

      &.general {
        width: 33%;
      }
      &.rest {
        width: 66%;
      }

      &.logos {
        width: 100%;
      }
    }
  }

  @media screen and (max-width: 720px) {
    .footerContentBlock {
      width: 100% !important;

      &.general {
        width: 100% !important;
      }
      &.rest,
      .restItem {
        width: 100% !important;
      }

      &.logos {
        width: 100% !important;
      }
    }
  }

  @media screen and (max-width: 640px) {
    .footerContent {
      padding: 6vh 0;
    }
  }

  .iconList {
    svg {
      width: 32px;
    }
  }

  .icon-twitter {
    position: relative;
    top: 2px;

    svg {
      path {
        fill: #005496;
      }
    }
  }
`

const FooterModule = ({ footerLinks, socials, subfooter }) => {
  // Build links for external or internal links
  const buildLinkItem = (link = {}, index, type) => {
    const key = `footer-link-${link?.link?.linkText}-${index}`
    return (
      <li className={type == 'rest' ? 'restItem' : ''} key={key}>
        <BuildLinkElement link={link.link} linkStyle="MenuLink" />
      </li>
    )
  }

  const buildSubLinkItem = (link = {}, index) => {
    const key = `footer-link-${link.linkText}-${index}`
    return (
      <div className="sublink" key={key}>
        <BuildLinkElement link={link} linkStyle="FooterLink" />
      </div>
    )
  }

  const buildSocialLink = (social) => {
    const Social = socialMap[social?.name]

    if (!Social) {
      return null
    }

    return (
      <a className={`social icon-${social?.name}`} href={social.href}>
        <Social />
      </a>
    )
  }
  return (
    <FooterWrapper>
      <PageWrapper className="contentWrapper">
        <div className="footerContent">
          {footerLinks &&
            footerLinks.map((links) => {
              return (
                <div
                  key={`footer-col-${links?.title}-0`}
                  className="footerContentBlock general"
                >
                  <h3> {links?.title} </h3>
                  <ul>
                    {links?.links &&
                      links.links.map &&
                      links.links.map((link, index) =>
                        buildLinkItem(link, index, 'general')
                      )}
                  </ul>
                </div>
              )
            })}

          <div
            key={`footer-col-socallinks-2`}
            className="footerContentBlock logos"
          >
            <Logo />
            <div className='iconList'>
              {buildSocialLink(socials?.linkedin)}
              {buildSocialLink(socials?.facebook)}
              {buildSocialLink(socials?.instagram)}
              {buildSocialLink(socials?.twitter)}
            </div>
            <div className='iconList'>
              {buildSocialLink(socials?.youtube)}
              {buildSocialLink(socials?.vimeo)}
              {buildSocialLink(socials?.pinterest)}
            </div>
          </div>
        </div>
      </PageWrapper>
      <div className="subFooterBlock">
        <PageWrapper className="contentWrapper">
          <div className="sublinkContainer">
            {subfooter &&
              subfooter.links &&
              subfooter.links.map &&
              subfooter.links.map((link, i) => {
                return buildSubLinkItem(link, i)
              })}
          </div>
          <div className="textContainer alignRight">
            <span
              style={{ display: 'block' }}
              className="allowListStyling"
              dangerouslySetInnerHTML={{
                __html: subfooter?.powered_by,
              }}
            />
          </div>
          <div className="textContainer alignRight">
            <span
              style={{ display: 'block' }}
              className="allowListStyling"
              dangerouslySetInnerHTML={{
                __html: subfooter?.partner_of,
              }}
            />
          </div>
        </PageWrapper>
      </div>
    </FooterWrapper>
  )
}
export default FooterModule
