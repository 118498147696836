import React from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import SearchbarV2 from '../../../../elements/searchbarV2'
import TabBlock from '../../../../modules/tabBlock'
import { fetchApi } from '../../../../utils/api/index'
import Loader from '../../../../elements/loader'
import useResize from '../../../../utils/useResize'

import SearchResults from './results'
// import { BuildLinkWrapper } from "../../../../elements/linkBuilder";
import BuildLinkElement from '../../../../elements/linkBuilder'
// import { ReadMoreButton } from "../../../../elements/typography";

import { useHistory } from 'react-router-dom'

const BodyChanges = createGlobalStyle`
  body, html {
    overflow: hidden !important;
    height: 100vh !important;
    width: 100vw !important;
  }
`

const SearchModalWrapper = styled.div`
  position: fixed;
  z-index: 10000099999;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.6);
  padding-top: 1rem;
  cursor: pointer;
  overflow: hidden;
  overflow-y: auto;

  &.noMedia {
    position: relative;
  }

  .searchContainer {
    z-index: 100000;
    margin: 0 auto;
    /* min-width: 880px; */
    width: 90%;
    max-width: 1000px;
    background-color: white;
    cursor: initial;
  }
  .link {
    text-align: center;
    padding: 1rem 0;

    a {
      font-size: 0.8rem;
    }
  }
  .tabs {
    padding-top: 2rem;
    padding: 2rem 2rem 0;
    position: relative;

    @media screen and (max-width: 680px) {
      padding: 22px;
    }

    .loadingIndicator {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.7);

      .loaderIcon {
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -45px 0 0 -45px;
      }
    }

    button {
      text-transform: capitalize !important;
      font-weight: 300 !important;
      color: #000 !important;

      &.Mui-selected {
        color: rgb(30, 167, 253) !important;
        font-weight: 400 !important;
      }
    }

    .MuiTabs-indicator {
      background-color: rgb(30, 167, 253) !important;
    }
  }

  @media screen and (max-width: 680px) {
    overflow-y: scroll;
    width: 100%;
    height: 100%;
    padding: 0;
    .searchContainer {
      width: 100%;
      padding-bottom: 140px;
    }
    .link {
      text-align: left;
      font-size: 14pt;
      padding: 0 2rem 1.5rem;
    }
  }

  .noresultscontainer {
    min-height: 480px;

    > div {
      padding: 22px;
      background: rgba(0, 0, 0, 0.1);
      margin: 12px 0;
      color: #000;
    }
  }
`

let searchdebounce = null
const SearchModal = ({ search, noMedia, setShowSearch }) => {
  const elementRef = React.useRef()
  const history = useHistory()
  const { width } = useResize()

  const [searchValue, setSearch] = React.useState(search.searchTerm || '')
  const [searched, setSearched] = React.useState('')
  const [searchResults, setSearchResults] = React.useState()
  const [loading, setLoading] = React.useState(false)
  const [tabIndex, setTab] = React.useState(0)
  const [modalTexts, setModalTexts] = React.useState(search.texts)
  const [lengthError, setError] = React.useState(false)
  const [searchTermMinLength] = React.useState(
    search.minSearchLength &&
      Number.isInteger(Number.parseInt(search.minSearchLength))
      ? Number.parseInt(search.minSearchLength)
      : 3
  )

  React.useEffect(() => {
    clearTimeout(searchdebounce)

    if (searchValue.length === 0) {
      setSearched('')
      setError(false)
      setLoading(false)
    } else if (
      searchValue.length > 0 &&
      searchValue.length < searchTermMinLength
    ) {
      setError(true)
    }

    if (searchValue.length >= searchTermMinLength) {
      setLoading(true)
      setError(false)
      searchdebounce = setTimeout(() => {
        handleSearch()
      }, 500)
    }
  }, [searchValue])

  React.useEffect(() => {
    const target = searchResults?.results ? searchResults : search
    // Use assign because we don't want to override the default
    const textTarget = Object.assign({}, target.texts)

    const currentCategory = getCategory('name')
    const currentCategoryShort = getCategory('short')

    textTarget.title = textTarget.title
      .replace(':category', textTarget.categories[currentCategoryShort])
      .replace(':count', target.results[currentCategory].totalItems)

    textTarget.readMore =
      textTarget.readMore.trim() +
      (currentCategoryShort === 'everything'
        ? ''
        : ` in de categorie ${currentCategory}`)

    setModalTexts(textTarget)
  }, [tabIndex, searchResults])

  const getCategory = (type) => {
    const target = searchResults?.results
      ? searchResults.results
      : search.results

    const categories = Object.keys(target)
    const selectedCategory = categories[tabIndex] || categories[0]

    return type === 'short' ? target[selectedCategory].key : selectedCategory
  }

  const link = {
    href: `${search.searchPage || '/zoeken'}?category=${getCategory('short')}${
      searched ? `&search=${searched}` : ''
    }`,
    linkText: modalTexts.readMore || 'Bekijk alle resultaten',
  }

  const handleSearch = () => {
    if (searchValue.length < searchTermMinLength || !searchValue) {
      setSearched('')
      setLoading(false)

      if (searchValue.length > 0) {
        setError(true)
      } else {
        setError(false)
      }
      return
    }

    setLoading(true)
    setError(false)
    setSearched(searchValue)
    let searchUrl = `${search.api}${searchValue && `?search=${searchValue}`}`

    fetchApi(searchUrl)
      .then((r) => {
        setSearchResults(r.search)
        setLoading(false)
      })
      .catch((e) => {
        console.error(e)
        setLoading(false)
      })
  }

  const data =
    searched && searched.length >= searchTermMinLength && !loading
      ? searchResults?.results || []
      : search?.results || []

  // let tabs = search?.results || [];
  // let tabs = []
  let tabs = data
    ? Object.keys(data).map((key) => {
        return {
          title: key,
          results: data[key],
          texts: modalTexts,
          searchValue,
        }
      })
    : []

  const handleClicksInoverlay = (e) => {
    const { pageX, pageY } = e
    const {
      left,
      top,
      bottom,
      right,
    } = elementRef.current.getBoundingClientRect()

    if (pageY >= top && pageY <= bottom && pageX >= left && pageX <= right) {
      return
    } else {
      setShowSearch(false)
      history.push(`${history?.location?.pathname}`)
    }
  }

  return (
    <SearchModalWrapper className={`${noMedia ? 'noMedia' : ''}`} onClick={handleClicksInoverlay}>
      <BodyChanges />
      <div className={`searchContainer`} ref={elementRef}>
        <SearchbarV2
          texts={modalTexts}
          search={searchValue}
          setSearch={setSearch}
          handleSearch={() => handleSearch()}
          fullWidth={true}
          lengthError={lengthError}
          handleAutoFocus={width >= 800}
        />
        <div className="padding">
          <div className="tabs">
            {tabs && tabs.length === 0 && (
              <div className="noresultscontainer">
                <div>{modalTexts.notFound || 'Geen resultaten gevonden'}</div>
              </div>
            )}
            <TabBlock
              tabs={tabs}
              InnerComponent={SearchResults}
              defaultTab={0}
              scrollButtons={width <= 480}
              setTab={setTab}
            />
            {loading && (
              <div className="loadingIndicator">
                <div className="loaderIcon">
                  <Loader />
                </div>
              </div>
            )}
          </div>
          <div className="link">
            {link?.href && (
              <BuildLinkElement
                link={link}
                linkStyle="ReadMoreButton"
                showArrow
              />
            )}
          </div>
        </div>
      </div>
    </SearchModalWrapper>
  )
}

export default SearchModal
