import React from 'react'
import styled from 'styled-components'

import { PageWrapper } from '../../elements/pageContainer'

// ICONS
import { ReactComponent as mail }  from './icons/envelope-regular.svg'
import { ReactComponent as facebook }  from './icons/facebook.svg'
import { ReactComponent as linkedin }  from './icons/linkedin.svg'
import { ReactComponent as whatsapp }  from './icons/whatsapp.svg'
import { ReactComponent as twitter }  from './icons/x-twitter.svg'

// Module styling
const SharePage = styled.div`
  .shareThisPageWrapper {
    > div {
      display: inline-block;
      margin-right: 20px;

      color: #005496;
      font-weight: bold;
      font-size: 1.2em;

      padding-bottom: 30px;

      @media screen and (max-width: 728px) {
        padding-bottom: 0;
      }

      &:nth-child(2) {
        position: relative;
        top: 8px;

        @media screen and (max-width: 728px) {
          padding-bottom: 30px;
        }
      }
    }
  }

  .share-icon {
    width: 30px;
    height: 30px;
    background: rgba(0,0,0,0.05);
    padding: 8px;
    border-radius: 6px;
    margin-right: 14px;
    display: inline-block;
    cursor: pointer;
    vertical-align: middle;

    &:hover {
      svg path {
        fill: #005496;
      }
    }
  }

  .shareText {
    position: relative;
    top: 8px;
  }
`

const ShareIconsWrapper = styled.div`
  img {
    margin-right: 8px;
    cursor: pointer;

    ${props => props.compact ? 'margin-top: 6px; width: 20px;' : 'width: 30px;'}
  }
`

const icons = [
  {
    Icon: mail,
    action: () => {
      const currentUrl = window.location.href
      const currentTitle = document.title
      window.location.href = `mailto:share@vorm.nl?subject=Vorm-${currentTitle}&body=please checkout this cool page: <a href'${currentUrl}'> Vorm </a>`
    }
  },
  {
    Icon: facebook,
    action: () => {
      window.open(`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`, '_blank')
    }
  },
  {
    Icon: linkedin,
    action: () => {
      window.open(`https://www.linkedin.com/shareArticle?mini=true&url=${window.location.href}`, '_blank')
    }
  },
  {
    Icon: whatsapp,
    action: () => {
      window.open(`https://api.whatsapp.com/send?text=urlencodedtext${document.title} on ${window.location.href}`, '_blank')
    }
  },
  {
    Icon: twitter,
    action: () => {
      window.open(`http://www.twitter.com/share?url=${window.location.href}&text=${document.title}`, '_blank')
    }
  }
]

export const PodcastsIcons = ({ links }) => {
  if(!links || !links.length) {
    return <div></div>
  }

  return <ShareIconsWrapper className='shareIcons'>
    {
      links.map((shareLink, index) => {
        const {Icon} = shareLink
        return <span key={`share-this-page-${index}`} className='share-icon' onClick={() => {
          shareLink.action()
        }}>
          <span> <Icon /> </span>
        </span>
      })
    }
  </ShareIconsWrapper>
}

const SharePageModule = ({
 label,
}) => {
  return (
    <SharePage>
      <PageWrapper>
        <div>
          {icons && icons.length && <div className='shareThisPageWrapper'>
            <div className='shareText'>
                {label || 'Share this page:'}
            </div>
            <div>
              <PodcastsIcons links={icons} />
            </div>
          </div>}
        </div>
      </PageWrapper>
    </SharePage>
  )
}
SharePage.defaultProps = {}
export default SharePageModule
