import React from 'react'
import { createGlobalStyle } from 'styled-components'
import { useHistory } from 'react-router-dom'

// Config
import menuItems from './components/menu/menuItems'
import { BuildLinkWrapper } from '../../elements/linkBuilder'
import { mocksearch } from '../../../stories/sections/header/module.stories'

// Core
import Menu from './components/menu'
import VimeoBlock from '../../modules/vimeoBlock'
import { PageWrapper } from '../../elements/pageContainer'
import { MainH1, P } from '../../elements/typography'
import BuildReactLink from '../../elements/linkBuilder'
import { ReactComponent as Arrow } from '../../../assets/svg/ic_button_arrow.svg'
import { ReactComponent as ChevronDown } from '../../../assets/svg/chevron_down.svg'
import { ReactComponent as MenuIcon } from './assets/menu.svg'
import { ReactComponent as LogoWhite } from '../../../assets/svg/logo_wit.svg'
import { ReactComponent as Search } from './assets/magnifying-glass-solid.svg'
import { ReactComponent as DividerIcon } from './assets/divider.svg'
import fof from './assets/404.png'

// Styling
import HeaderWrapper from './header.style'
import BigHeaderMenu from './components/bigMenu'
import SearchModal from './components/searchModal'

// Scroll Lock
const GlobalStyle = createGlobalStyle`
  body {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
`

// Option item component
const OptionComponent = ({ isActive, item }) => {
  return (
    <li className={`${isActive ? 'active' : ''}`}>
      <BuildLinkWrapper link={{ href: item?.link?.href || '/' }}>
        <span>{item?.link?.linkText || item.text}</span>
      </BuildLinkWrapper>
    </li>
  )
}

let interval = null

const HeaderOptions = ({ headerOptions }) => {
  const [activeItem, setActiveItem] = React.useState(1)

  const getMarginFromIndex = () => {
    const size = 74

    if (activeItem == 0) {
      return `0px`
    }

    return `-${size * activeItem}px`
  }

  React.useEffect(() => {
    clearInterval(interval)
    interval = setInterval(() => {
      let nextIndex = activeItem + 1

      if (nextIndex > headerOptions?.length - 1) {
        nextIndex = 0
      }

      setActiveItem(nextIndex)
    }, 4000)

    return () => clearInterval(interval)
  }, [interval, setActiveItem, activeItem])

  return (
    <div className="headerOptions">
      <div className="housing">
        <ul
          style={{
            top: getMarginFromIndex(),
          }}
          className="listContainerHeader"
        >
          {headerOptions &&
            headerOptions.map &&
            headerOptions.map((item, i) => (
              <OptionComponent
                isActive={i === activeItem}
                id={i}
                key={`header-option-${i}`}
                item={item}
                setActive={setActiveItem}
              />
            ))}
        </ul>
        <Arrow className="floatingArrow" />
      </div>
    </div>
  )
}

// Menu config
const Header = ({
  title,
  text,
  link,
  headerOptions,
  showHeaderOptions,
  image,
  video = null,
  leftMenuContent,
  rightMenuContent,
  errorPage,
  goToFirstSection,
  mainMenu,
  overlayColor = 'rgba(7,32,50,0.15)',
  isSearch = false,
  search = mocksearch,
  noMedia = false,
}) => {
  // Routing
  const history = useHistory()
  const passedSearch =
    search === '' ||
    !search ||
    (typeof search === 'object' && Object.keys(search).length === 0)
      ? mocksearch
      : search
  // State
  const [isOpen, setIsOpen] = React.useState(false)
  const [showSearch, setShowSearch] = React.useState(history?.location?.hash)

  React.useEffect(() => {
    setShowSearch(history?.location?.hash === '#zoekenbar')
  }, [history.location])

  return <>
    <HeaderWrapper
      isSearch={isSearch}
      backgroundImage={image?.src}
      bgPos={image?.position}
      overlayColor={overlayColor}
      showHeaderOptions={showHeaderOptions}
      isOpen={isOpen}
      className={`${noMedia ? 'noMediaVariant' : 'mediaElement'} ${isOpen ? 'menuOpen' : ''} ${
        errorPage ? 'errorPage' : ''
      }`}
    >
      {isOpen && <GlobalStyle />}
      {
        <BigHeaderMenu
          open={isOpen}
          toggleOpen={setIsOpen}
          leftMenuContent={leftMenuContent}
          rightMenuContent={rightMenuContent}
          noMedia={noMedia}
        />
      }
      <div className="backgroundElement blurContainer">
        {!noMedia && video && video.id && (
          <div className="vimeoContainer">
            <VimeoBlock
              video={video}
              autoplay={true}
              controls={false}
              loop={true}
              muted={true}
            />
          </div>
        )}
        <div className="headerOverlay">
          <div className="pageContainer">
            <PageWrapper className="contentWrapperHeader">
              <div className="contentPositionHeader">
                {!errorPage && !noMedia && !isSearch && title && (
                  <div className="headerTitle">
                    <MainH1>{title}</MainH1>
                    {text && <P text={text} />}
                    {link?.href && (
                      <BuildReactLink
                        link={link}
                        linkStyle="ReadMoreButton"
                        showArrow
                      />
                    )}
                  </div>
                )}

                {!errorPage && !noMedia && !isSearch && showHeaderOptions && (
                  <HeaderOptions headerOptions={headerOptions} />
                )}
                {!noMedia && errorPage && (
                  <div className="errorContainer">
                    <img
                      src={fof}
                      alt="Pagina niet gevonden"
                      title="Pagina niet gevonden"
                      style={image?.position ? { objectPosition: image?.position } : {}}
                    />
                  </div>
                )}
                {
                  <>
                    <div className="menuWrapper">
                      <LogoWhite
                        className="logoElement"
                        onClick={() => history.push('/')}
                      />
                      <Menu links={mainMenu || menuItems} />
                    </div>
                    <div className="toggleMenu">
                      <div onClick={() => setIsOpen(!isOpen)}>
                        <MenuIcon />
                      </div>
                      {!isSearch && (
                        <div className="divider">
                          <DividerIcon />
                        </div>
                      )}
                      {!isSearch && (
                        <div className="searchWrapper">
                          <div
                            onClick={() => {
                              setShowSearch(true)
                              history.push(
                                `${history?.location?.pathname}#zoekenbar`
                              )
                            }}
                          >
                            <Search />
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                }
              </div>
            </PageWrapper>
          </div>
          {!isSearch && !noMedia && (
            <div className="chevronWrapper" onClick={goToFirstSection}>
              <ChevronDown />
            </div>
          )}
        </div>
      </div>
    </HeaderWrapper>
    {showSearch && <SearchModal setShowSearch={setShowSearch} search={passedSearch} />}
  </>
}
Header.defaultProps = {}
export default Header
