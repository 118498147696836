import React from 'react'
import styled from 'styled-components'
// import { useHistory } from 'react-router-dom'

// import img from '../../../assets/media/building.png'
import BuildImage from '../../elements/imageBuilder'
import BuildLinkElement from '../../elements/linkBuilder'

import { RecentProjectsCardH3, P } from '../../elements/typography'
import { BuildLinkWrapper } from '../../elements/linkBuilder'

import { PodcastsIcons } from '../podcastDetails'

const CardWrapper = styled.div`
  width: 33%;
  padding: 0 20px 20px 0;
  display: inline-block !important;
  vertical-align: top;

  ${props => props.fullWidth ? 'width: 100%; padding: 0;' : ''}

  @media screen and (max-width: 1000px) {
    width: 100%;
    padding: 0;
  }

  .imageContainer {
    height: 250px;
    max-height: 278px;
    width: 100%;
    display: inline-block;
    overflow: hidden;
    margin-bottom: 12px;
    cursor: pointer;

    @media screen and (max-width: 1820px) {
      height: 190px;
      max-height: 190px;
    }

    @media screen and (max-width: 1000px) {
      max-height: 20rem;
      height: auto;
    }

    img {
      object-fit: cover;
      object-position: center center;
      min-width: 100%;
      min-height: 100%;
      height: 100%;

      ${props => props.imagePosition ? `

        object-position: ${props.imagePosition};
      ` : ''}
    }
  }

  .imageContainer {
    &.noLinkCard {
      cursor: initial !important;
    }
  }

  .newsCardContent,
  .imageContainer {
    display: inline-block;
    vertical-align: top;
  }

  .newsCardContent {
    width: 100%;
  }

  p {
    margin: 0;
    padding: 0 0 12px;
    display: block;
  }

  .newsMeta {
    color: #005496;
    font-size: 0.8rem;
    min-height:32px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    > div {
      display: inline-block;
      vertical-align: middle;
      height:32px;
      line-height: 32px;
      width: auto;
      //min-width: 130px;
      //width: 50%;

      &:first-child {
          margin-right: 0.5rem;
      }

      &:nth-child(2) {
        text-align: right;
      }
    }
  }

  strong {
    font-weight: 400;
  }

  ${(props) =>
    !props.showMoreCardInformation
      ? `
    h3 {
      white-space: nowrap;
      overflow: hidden;
    }

    p {
      overflow: hidden;
      height: 48px;
      margin-bottom: 5px;
      margin-top: 8px;
    }
  `
      : `
    h3 {
      height: 55px;
      overflow: hidden;
      white-space: initial;
    }

    p {
      overflow: hidden;
      height: 102px;
      margin-bottom: 15px;
      margin-top: 18px;
    }
  `}

  .imageContainer {
    position: relative;
    .imageLabel {
      position: absolute;
      top: 10px;
      left: 10px;
      padding: 10px 10px 8px;
      background: rgba(0,84,150,0.7);
      border-radius: 8px;
      color: #fff;
      font-size: 14px;
      line-height: 14px;

      &.highlighted {
        background: rgba(5,175,241,0.7);
      }
    }
  }
`

const Card = ({
  image,
  title,
  text,
  link,
  meta,
  showMoreCardInformation = true,
  imagePosition,
  podcastLinks,
  fullWidth,
  label,
  dontGoToDetailPage
}) => {
  // const history = useHistory()

  if(!title && !text) {
    return null
  }

  return (
    <CardWrapper fullWidth={fullWidth} imagePosition={imagePosition} showMoreCardInformation={showMoreCardInformation}>
      <div className={`imageContainer ${dontGoToDetailPage ? 'noLinkCard' : ''}`}>
        {!dontGoToDetailPage && <BuildLinkWrapper link={link}>
          <BuildImage image={image} width={442} height={250} />
          {label && label.text && <span className={`imageLabel ${label.highlighted ? 'highlighted' : ''}`}>{label.text}</span>}
        </BuildLinkWrapper>}

        {dontGoToDetailPage && <>
          <BuildImage image={image} width={442} height={250} />
          {label && label.text && <span className={`imageLabel ${label.highlighted ? 'highlighted' : ''}`}>{label.text}</span>}
        </>}
      </div>
      <div className="newsCardContent">
        <div className="newsMeta">
          <div>{meta}</div>

          {podcastLinks && <PodcastsIcons compact links={podcastLinks} />}
        </div>
        <RecentProjectsCardH3>
          {!dontGoToDetailPage && <BuildLinkWrapper link={link}> {title} </BuildLinkWrapper>}
          {dontGoToDetailPage && <span> {title} </span>}
        </RecentProjectsCardH3>

        <P dontCrop text={text} />
        {!dontGoToDetailPage && <BuildLinkElement link={link} linkStyle="ReadMoreButton" showArrow />}
      </div>
    </CardWrapper>
  )
}
Card.defaultProps = {}
export default Card
